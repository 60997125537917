<template>
  <div class="products">
    <top-nav @menu_data="getMenuData" />
    <div class="products_content">
      <div class="blank12"></div>
      <div class="inner_card">
        <div class="first_box">
          <img src="../../assets/imgs/app/app-arc-logo-black@2x.png" alt />
        </div>
        <div class="second_text">ARC Agent</div>
        <div
          class="third_text"
        >ARC is a fast, mobile based property platform that is designed and developed for project marketing professionals. It carries the grand mission to achieve true automation and powerful network effects in a virtual marketplace where developers, property agents, mortgage brokers, solicitors and buyers could all connect and make deals happen.</div>
        <div class="fourth_downloading" @click="handleClick(1)">Download</div>
      </div>
      <div class="inner_card">
        <div class="first_box">
          <img src="../../assets/imgs/app/app-arc-logo-black@2x.png" alt />
        </div>
        <div class="second_text">ARC partner</div>
        <div
          class="third_text"
        >ARC Partner Portal, is currently a web based platform designed and developed to track leads, to and from referral partners, and digital loan submission for the dedicated Equitymate (little to zero deposit) loan product. 
The portal provides the dedicated lead generation funnel via the Fact find affiliate links. The inbuilt links provide the users more power to generate leads via social media and from referral partners, with all information merging into the ARC CRM.</div>
        <div class="fourth_downloading" @click="handleClick(2)">User Login</div>
      </div>
      <div class="inner_card">
        <div class="first_box">
          <img src="../../assets/imgs/app/app-products2@2x.png" alt />
        </div>
        <div class="second_text">DragonVR</div>
        <div class="third_text">
          Based both in Sydney and Shenzhen, we’ve gained the reputation as a fast turnaround and high quality development team. We provide our clients with cost-effective solutions in real estate VR production.
          <br />Our teams are made up of members who carry high level of VR expertise and experience in project marketing. This allows us and the vendors to seamlessly collaborate to create state of the art VR experience across all of client’s digital touch points.
        </div>
        <div class="fourth_downloading" @click="handleClick(3)">Learn More</div>
      </div>
      <div class="inner_card" style="margin-bottom:70px;">
        <div class="first_box">
          <img src="../../assets/imgs/app/app-equitymate@2x.png" alt />
        </div>
        <div class="second_text">EquityMate</div>
        <div class="third_text">
             A dedicated program to assist Australian first home buyers and investors to purchase a property with little to zero deposit. EquityMate's digital processing is exclusive to the ARC platform.
        </div>
        <div class="fourth_downloading" @click="handleClick(4)">Learn More</div>
      </div>
      <div class="bottom_link">
        <footer-nav/>
        <!-- <span>Copyright 2021 © Arcanite</span>
        <span @click="goRouter('/privacyPolicy')">Privacy Policy</span> -->
      </div>
    </div>
    <menu-bar :isShow="isShow" @changeIsShow="changeShow" />
  </div>
</template>
<script>
import FooterNav from './FooterNav.vue';
export default {
  data() {
    return {
      showMenuFlag: "0",
      isShow: false,
      phoneType: "0" //0其他 1苹果 2安卓,
    };
  },
  created() {
    if (navigator.userAgent.indexOf("iPhone") >= 0) {
      this.phoneType = "1";
    } else if (navigator.userAgent.indexOf("Android") >= 0) {
      this.phoneType = "2";
    } else {
      this.phoneType = "0";
    }
  },
  methods: {
    goRouter(path) {
      this.$router.push(path);
    },
    getMenuData(val) {
      val == "1" ? (this.isShow = true) : (this.isShow = false);
    },
    changeShow() {
      this.isShow = false;
    },
    handleClick(val) {
      if (val == 1) {
        if (this.phoneType == "1") {
          window.location.href =
            "https://apps.apple.com/au/app/arc-agent/id1510107896";
        } else {
          window.location.href =
            "https://play.google.com/store/apps/details?id=au.com.arcnet.agent";
        }
      } else if (val == "2") {
        window.open("https://partner.arcnet.com.au/", "_blank");
      } else if (val == "3") {
        window.open("https://www.dragonvr.info/", "_blank");
      } else {
        window.open(
          "https://www.equitymate.com.au/#/?originatortype=1&originatorid=1448662277",
          "_blank"
        );
      }
    }
  },
  components: {
    "top-nav": resolve => require(["./TopNav.vue"], resolve),
    "footer-nav": resolve => require(["./FooterNav.vue"], resolve),
    "menu-bar": resolve => require(["./MenuBar.vue"], resolve)
    // FooterNav
  }
};
</script>
<style lang="scss" scoped>
.products {
  padding-top: 70px;
  .products_content {
    background-color: #f7f7f7;
    text-align: left;
    padding: 0 12px 17px;
    .inner_card {
      background-color: #fff;
      margin-bottom: 12px;
      padding: 12px;
      .first_box {
        height: 46px;
        img {
          height: 100%;
        }
      }
      .second_text {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.21px;
        color: #000000;
        margin-top: 16px;
      }
      .third_text {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.23px;
        color: #1e1d1e;
        margin-top: 16px;
      }
      .fourth_downloading {
        text-align: right;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.31px;
        text-align: right;
        color: #5291f7;
        margin-top: 29px;
      }
      .fourth_downloading:hover {
        cursor: pointer;
      }
    }
    .footer_text {
      font-family: Arial;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #9b9b9b;
      margin-top: 60px;
    }
  }
  .blank12 {
    height: 12px;
    background-color: #f7f7f7;
  }
  .bottom_link {
    margin-top: 20px;
    font-family: Arial;
    font-size: 12px;
    color: #9b9b9b;
    text-align: left;
    span {
      margin-right: 25px;
    }
  }
}
</style>